// body.page_mobileform
//// .control-label.col-sm-3
////   width: 20%
//// .col-sm-9
////   width: 80%
//// .main
////   background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png')
////   background-size: cover
////   background-position: center center
////   background-repeat: no-repeat
////   margin-top: -1px
////   padding-top: 37px
////   padding-bottom: 40px
.module.module-form {
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  padding-top: 37px;
  padding-bottom: 40px;
  label {
    font-size: 16px;
    font-weight: bold;
    word-break: keep-all;
    max-width: unset; }
  input {
    width: 100%; }
  #_Form_Section_62, #_Form_87 {
    width: 60%;
    max-width: 800px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    .formBS, .form-group {
      border: none; }
    .formBS {
      display: flex;
      flex-wrap: wrap; }
    .form-group {
      width: 100%;
      margin-bottom: 40px;
      [class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
        border-left: none; }
      [class*="col-"]:first-child {
        border-right: none; }
      &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        width: 50%; }
      &:nth-child(8) {
        display: flex;
        flex-wrap: wrap;
        .control-label.col-sm-3 {
          margin-bottom: 20px;
          width: 100%; }
        .col-sm-9 {
          width: 100%; } }
      &.authcode {
        display: flex;
        align-items: center;
        .control-label.col-sm-3 {
          width: 20%;
          max-width: 70px; }
        .col-sm-9 {
          width: 50%;
          max-width: 200px; }
        .form-btn {
          width: 30%;
          max-width: 150px;
          margin-left: 30px;
          margin-right: auto; } } }


    label {
      color: #fffac5; }
    .form-btn {
      width: 100%;
      &:active, &:focus {
        background: transparent; } }
    .btn.btn-s.btn-login {
      display: none; } } }

.module.module-form {
  #_Form_87 {
    .form-group {
      &:nth-child(4), &:nth-child(5) {
        width: 100%; }
      &:nth-child(5) {
        .form-group {
          margin-bottom: 0;
          padding-top: 40px; } }
      &.authcode .control-label.col-sm-3 {
        width: 100%;
        max-width: 187px; } }
    .form-btn {
        width: 30%;
        max-width: 150px;
        margin-left: auto;
        margin-right: auto; } } }

#Form_Msg_62, #Form_Msg_87 {
  width: 60%;
  max-width: 800px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  p.help-block {
    color: #fff; } }

// body.page_mobileform
//   #Form_Msg_62, #_Form_87
//     width: 60%
//     max-width: 800px
//     margin: auto
//     padding-left: 15px
//     padding-right: 15px
//     p.help-block
//       color: #fff



// @media screen and (min-width: 768px)  and (max-width: 1260px)
//   body.page_mobileform
//     label
//       font-size: 13px
//     #_Form_Section_62
//       width: 80%



// @media screen and (max-width: 767px)
//   body.page_mobileform
//     label
//       font-size: 14px
//     .main
//       padding-top: 0
//     #_Form_Section_62
//       width: 100%
//       padding-left: 30px
//       padding-right: 30px

//   body.page_mobileform
//     #Form_Msg_62
//       width: 100%
//       padding-left: 30px
//       padding-right: 30px

// @media screen and (max-width: 576px)
//   body.page_mobileform
//     #_Form_Section_62
//       .form-group
//         &.authcode
//           flex-wrap: wrap
//           .control-label.col-sm-3
//             width: 20%
//             max-width: unset
//           .col-sm-9
//             width: 80%
//             max-width: unset
//           .form-btn
//             width: 100%
//             margin-left: auto
//             margin-right: auto
//             margin-top: 30px

@media screen and (min-width: 768px)  and (max-width: 1260px) {
  .module.module-form {
    label {
      font-size: 13px; }
    #_Form_Section_62, #_Form_87 {
      width: 80%; } } }
@media screen and (max-width: 767px) {

  .module.module-form {
    label {
      font-size: 14px; }
    .main {
      padding-top: 0; }
    #_Form_Section_62, #_Form_87 {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px; } }

  #Form_Msg_62, #Form_Msg_87 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px; } }

@media screen and (max-width: 576px) {
  .module.module-form {
    #_Form_Section_62, #_Form_87 {
      .form-group {
        &.authcode {
          flex-wrap: wrap;
          .control-label.col-sm-3 {
            width: 20%;
            max-width: unset; }
          .col-sm-9 {
            width: 80%;
            max-width: unset; }
          .form-btn {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px; } } } }
    #_Form_87 {
      .form-btn {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px; } } } }



