@import "./import/default.sass";
nav.sidebar.cus-product-side {
  ul.main-menu {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
    // overflow-y: scroll
    scrollbar-width: none;
    transition: 0.5s;
    .sub-menu {
      display: none; }


    &::-webkit-scrollbar {
      display: none; }
    li {
      position: relative;
      text-align: center;
      float: none;
      &.first-list {
        width: 100%;
        background: #fff;
        margin-bottom: 5px;
        > a {
          position: relative; }
        &.plus {
          > a {
            &::after {
              content: '+';
              position: absolute;
              display: block;
              top: 50%;
              right: 10px;
              transform: translateY(-50%); } }
          &.cus-close {
            > a {
              &::after {
                content: '-'; } } } } }

      .active {
        background: #000;
        a {
          color: $color_main; } } }

    a {
      line-height: 1.3;
      color: #000;
      font-size: 16px;
      display: block; }

    > li {
      position: relative;
      padding: 5px 0;
      li {
        transition: .2s;
        padding: 10px 0; }
      > a {
        margin: 5px 0;
        // padding-bottom: 5px
        display: block;
        font-weight: bold; } }

    h2.border-green {
      position: relative;
      &::after {
        content: '';
        display: block;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background: #000; } } } }
#sideBarTag {
  display: none; }


/* header Nav End */

@media screen and (max-width: 767px) {
  nav.sidebar.cus-product-side {
    position: fixed;
    width: 220px;
    left: -220px;
    transition: .3s linear;
    z-index: 1000;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 30px;
    padding-top: 30px;
    background: #333;


    .main-menu {
      position: relative; }

    #sideBarTag {
      display: block;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 60px;
      border-radius: 0 4px 4px 0;
      background: #ccc;
      color:#333 {}
      display: flex;
      justify-content: center;
      align-items: center; }

    &.open {
      left: 0px;
      #sideBarTag {
        span {
          transform: rotate(180deg); } } }
    ul.main-menu li.first-list {
      background: $color_main; } } }







