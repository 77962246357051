/* header Nav */
@import "./import/default.sass";
body {
  overflow-x: hidden; }

.main-header {
  padding-top: 60px;

  .logo {
    padding: 10px;
    z-index: 999;

    h1 {
      margin-top: 0;
      padding: 10px 0; }

    a {
      display: block;
      width: 150px;

      img {
        vertical-align: middle; } } } }

@media screen and (min-width: 992px) {
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    background: #212425;
    .logo {
      h1 {
        margin-left: 90px; } } }

  .main-nav {
    display: flex;
    align-items: center;
    padding-right: 200px; } }
@media screen and (max-width: 1275px) {
  .main-header {
    .logo {
      a {
        width: 70px; }
      h1 {
        margin-left: 0px; } } }
  .main-nav {
    padding-right: 0;
    #main-menu {
      >li:last-child {
        ul.sub-menu {
          min-width: 100px;
          ul.sub-menu {
            min-width: 150px; } } } } } }


@media screen and (max-width: 991px) {
  .main-header .logo {
    position: fixed;
    height: 60px;
    align-items: center;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    transform: translateX(0);
    transition: 0.5s;
    background: rgba(0,0,0, .8);
    a {
      width: 80px; }
    h1 {
      margin-top: 0px; } } }

#main-menu {
  display: flex;
  align-items: center;
  > li {
    position: relative;

    > a {
      font-size: 18px;
      letter-spacing: 1px;
      color: #fff;
      font-weight: bold;
      word-break: keep-all; }
    li {
      position: relative;
      transition: .2s;
      // padding: 10px 0
      a {
        color: #5b5b5b;
        transition: .2s; } } } }

// @media screen and (min-width: 1400px)
//   #main-menu
//     > li
//       > a
//         font-size: 16px
//   .main-header
//     .logo
//       padding-left: 30px

.main-header .dropdown-bg {
  display: none; }

@media screen and (min-width: 992px) {
  #main-menu {
    li {
      &:hover {
        background: #212425;
        > a {
          color: $color_main; } } }
    > li {
      position: relative;
      padding: 35px 0px;

      &:hover {
        background: transparent;
        &::after {
          border-color: $color_main transparent transparent transparent; } }
      &.after-none {
        &::after {
          display: none; } }
      li {
 }        // padding: 5px 0
      > ul.sub-menu {
        display: none;
        position: absolute;
        z-index: 100;
        min-width: 170px;
        opacity: 0;
        transition: 0.3s linear;
        width: 100%;
        text-align: center;
        background: rgba(#fff, .9);
        box-shadow: 0 5px 5px rgba(0,0,0,.2);
        ul.sub-menu {
          background: rgba(#fff, .9);
          position: relative;
          display: none;
          // height: 0
 }          // overflow: hidden


        &.open {
          display: block; }

        &.show {
          opacity: 1;
          top: 0;
          &.right {
            left: auto;
            right: 100%; } } }
      // ul.sub-menu
      //   display: none
      //   position: absolute
      //   left: 100%
      //   z-index: 100
      //   opacity: 0
      //   transition: 0.3s linear
      //   width: auto
      //   min-width: 150px
      //   text-align: center
      //   background: rgba(#fff, .9)
      //   box-shadow: 0 5px 5px rgba(0,0,0,.2)
      //   ul.sub-menu
      //     min-width: 180px

      //   &.open
      //     display: block

      //   &.show
      //     opacity: 1
      //     top: 0
      //     &.right
      //       left: auto
 } } }      //       right: 100%



@media screen and (max-width: 991px) {
  #main-menu {
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; }
    a {
      padding: 5px 0; }

    // li.get-height
    //   ul.sub-menu
    //     position: absolute
    //     visibility: hidden
    //     top: 0
    //     left: 0
    //     width: 100%
    //     height: auto

    > li {
      // padding: 5px 0
      > a {
        margin: 5px 0; } }
    li {
      ul.sub-menu {
        display: none;
        // position: relative
        // overflow: hidden
        // top: auto
        // left: auto
        // height: 0
        background: #000;
 } } }        // background: $color_main

  .main {
    width: 100%;
    transition: .5s; } }




@media screen and (min-width: 992px) {
  .main-header .dropdown-bg {
    width: 120px;
    height: 1px;
    position: absolute;
    z-index: -1;
    background: #d6b161;
    transform-origin: 50% 0;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s, opacity 0.1s, transform 0.2s;

    &.open {
      opacity: 1; } }

  #main-menu {
    li {
      &.trigger-ul > ul.sub-menu {
        display: block;
        z-index: 11111; }

      &.trigger-show-ul > ul.sub-menu {
        opacity: 1; } }

    > li > ul.sub-menu {
      top: 92px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100; }
    ul.sub-menu a {
      padding: 10px 15px; }
    a {
      box-sizing: border-box;
      display: block;
      padding: 0px 15px;
      color: #000;
      text-decoration: none;
      font-size: 16px; } } }
@media screen and (min-width: 1520px) {
  #main-menu {
    a {
      padding: 0 15px; } } }


/* phone menu open */
@media screen and (max-width: 991px) {
  .main-header {
    background: rgba(0,0,0, .8); }
  #main-menu {
    position: fixed;
    padding-top: 100px;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    height: 100%;
    width: 250px;
    background: $color_main;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    z-index: 10000;
    overflow-y: scroll;
    li {
      width: 100%;
      text-align: center; } }

  .menu {
    .main-header {
      #main-menu {
        transform: translateX(0); }
      .logo {
        transform: translateX(250px); } }
    .main {
      transform: translateX(250px); } }

  #main-menu {
    li.nav-list-item {
      a {
        font-size: 14px;
        color: #fff;
        display: block;
        width: 100%; }
      &.second {
        li > a {
          color: #d6b161; } } } }

  .menuButton {
    width: 20px;
    height: 20px;
    top: 20px;
    cursor: pointer;
    transition: 0.5s;
    right: 30px;
    z-index: 1000;
    position: fixed;

    > span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      font-size: 0;
      margin: 5px 0;
      transition: 0.5s; }

    &::before, &::after {
      transform-origin: center;
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: 0.5s; } }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: none; }


  .menu {
    body {
      position: fixed;
      overflow: hidden;
      width: 100%; }

    .mask {
      display: block; }

    .menuButton {
      transform: rotate(180deg);

      &::before {
        transform: translate(0, 12px) rotate(-45deg); }

      > span {
        opacity: 0; }

      &::after {
        transform: translate(0, -3px) rotate(45deg); } } } }

/* header Nav End */

@media screen and (max-width: 414px) {
  #main-menu {
    width: 60%; }

  .menu {
    .main-header {
      .logo {
        transform: translateX(60%); } }
    .main {
      transform: translateX(60%); } } }


