.cus-success-page {
  background-image: url('/ezfiles/764/1764/img/217/case_car_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  margin-bottom: -1px;
  padding-top: 40px;
  padding-bottom: 55px;

  .gallery-top {
    .swiper-button-next,
    .swiper-button-prev {
      display: none; } }

  > .custom-container {
    .custom-row {
      flex-direction: column;
      margin-bottom: 32px;
      .car-selector {
        margin-left: auto; } } }
  .show-cars {
    display: none;

    img {
      width: 100%; }
    .txt {
      font-size: 0;
      span {
        display: inline-block;
        margin-top: 15px;
        font-size: 18px;
        color: #fff;
        line-height: 1;
        & + span {
          position: relative;
          padding-left: 30px;
          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 15px;
            top: 0px;
            width: 1px;
            height: 100%;
            background: #fff; } } } }
    .swiper-container.gallery-top {
      margin-bottom: 35px; } }

  .common-title {
    margin-top: 0; }
  .custom-container.cus-fluid {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 100%; }
  .custom-container.thumbs {
    padding-left: 0;
    img {
      width: auto;
      height: 73px !important; }
    .swiper-slide {
      width: auto;
 }      // max-width: 91px
    .swiper-button-next,
    .swiper-button-prev {
      top: 50%;
      transform: translateY(-50%);
      width: 13px;
      height: 22px;
      margin-top: 0px;
      background-size: 13px 22px; }
    .swiper-button-next {
      right: -10px; }
    .swiper-button-prev {
      left: -10px; }
    .cus-col-6 {
      position: relative;
      width: auto; } } }

@media screen and (max-width: 767px) {
  .cus-success-page {
    .custom-container.thumbs {
      img {
        width: 100%;
        height: auto !important; } } } }

.car-selector {
  position: relative;

  select {
    width: auto;
    padding: 5px 25px 5px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    height: 30px;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/ezfiles/764/1764/img/217/select_arrow.svg) 86% 52% / 10% no-repeat #eee; }
  select::-ms-expand {
    display: none; } }

// @media screen and (max-width: 992px)
//   .cus-success-page
//     .custom-container.thumbs
//       .cus-col-6
//         width: 80%

@media screen and (max-width: 767px) {
  .cus-success-page {
    .gallery-top {
      .swiper-button-next,
      .swiper-button-prev {
        display: block; }
      .swiper-button-next {
        right: 20px; }
      .swiper-button-prev {
        left: 20px; } }

    .custom-container.thumbs {
      padding-right: 0;
      padding-left: 0;
      .cus-col-6 {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px; } }

    .show-cars {
      .txt {
        padding-left: 30px;
        span {
          font-size: 15px; } } } } }

@media screen and (max-width: 576px) and (min-width: 481px) {
  .cus-success-page {

    .custom-container.thumbs {
      .cus-col-6 {
        padding-left: 45px;
        padding-right: 45px; }
      .swiper-button-next {
        right: 25px; }
      .swiper-button-prev {
        left: 25px; } } } }

@media screen and (max-width: 480px) {
  .cus-success-page {
    .custom-container.thumbs {
      .cus-col-6 {
        padding-left: 30px;
        padding-right: 30px; }
      .swiper-button-next {
        right: 10px; }
      .swiper-button-prev {
        left: 10px; } } } }

