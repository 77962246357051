.cus-about-page {
  p {
    color: #fff;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 1.65;
    font-weight: normal; }
  .about-mission {
    padding-top: 37px;
    padding-bottom: 40px;
    background: #202425;
    img {
      width: 100%;
      max-width: 200px;
      margin: auto;
      display: block; }
    .common-title {
      margin-top: 0; }
    .txt {
      margin-top: 27px; }
    .custom-container {
      .custom-row:nth-child(2) {
        width: 70%;
        margin: auto;
        .cus-col-4 {
          padding-left: 45px;
          padding-right: 45px; } } } }
  .about-section-1 {
    .about-intro {
      background-image: url('/ezfiles/764/1764/img/217/about_banner1.png');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: -1px;
      .common-title {
        margin-bottom: 20px;
        white-space: nowrap; }

      .custom-wrap {
        padding: 90px 0;
        width: 46%;
        margin-right: auto;
        position: relative;

        .text-area {
          width: 70%;
          margin: auto;
          z-index: 100;
          position: relative; }
        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(#000, .6);
          border-radius: 4px;
          transform: skewX(-10deg); } }
      p.comment {
        margin-top: 10px;
        font-size: 10px; }

      &.reverse {
        background-image: url('/ezfiles/764/1764/img/217/about_banner2.png');
        .custom-wrap {
          margin-left: auto;
          margin-right: unset;
          &::before {
            transform: skewX(10deg); } } } } } }

@media screen and (min-width: 1300px) {
  .cus-about-page {
    p {
      line-height: 2; } } }

@media screen and (min-width: 992px) {
  .cus-about-page {
    .about-section-1 {
      .about-intro {
        .custom-wrap {
          min-height: 585px; } } } } }


@media screen and (max-width: 991px) {
  .cus-about-page {
    p {
      font-size: 14px; }
    .about-mission {
      .custom-container {
        .custom-row:nth-child(2) {
          width: 90%; } } }
    .about-section-1 {
      .about-intro {
        .common-title {
          white-space: normal; }
        .custom-wrap {
          padding: 45px 0;
          width: 100%; } } } } }

@media screen and (max-width: 767px) {
  .cus-about-page {
    .about-mission {
      .custom-container {
        .custom-row:nth-child(2) {
          width: 100%;
          .cus-col-4 {
            padding-left: 15px;
            padding-right: 15px; } } } } } }
.cus-about-page {
  .about-section-2 {
    padding-top: 115px;
    padding-bottom: 125px;
    position: relative;
    background-image: url('/ezfiles/764/1764/img/217/about_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // p
    //   font-size: 16px
    img {
      width: 100%; }
    .common-title {
      margin: 0;
      margin-bottom: 20px;
      text-align: left; }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #202425;
      z-index: -1; } } }
.cus-about-page.company-intro {
  .about-section-2 {
    .custom-row {
      flex-wrap: nowrap;
      .txt {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: #000;
        padding: 30px 45px; }

      &:first-child {
        .cus-col-6 {
          &:first-child {
            width: 53%;
            background: transparent;
            margin-left: -6%; }
          &:nth-child(2) {
            width: 58%;
            margin-right: -5%; } } }

      &:nth-child(2) {
        margin-top: 160px;
        .cus-col-6 {
          &:first-child {
            width: 53%;
            background: transparent;
            margin-right: -6%; }

          &:nth-child(2) {
            width: 58%;
            margin-left: -5%; } }
        .common-title {
          text-align: right; } } } } }

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .cus-about-page {
    .about-section-2 {
      .custom-row {
        .txt {
          max-height: 75%; }
        &:nth-child(2) {
          .txt {
            max-height: 83%; } } } } } }

@media screen and (max-width: 991px) {
  .cus-about-page.company-intro {
    .about-section-2 {
      .custom-row {
        flex-wrap: wrap; } } }
  .cus-about-page {
    .about-section-2 {
      padding-top: 45px;
      .common-title {
        margin-top: 20px;
        text-align: center;
        font-size: 18px; }
      .custom-container.odd-reverse {
        .custom-row {
          .txt {
            padding: 20px 30px;
            height: auto; }
          &:first-child {
            .cus-col-6 {
              &:first-child {
                width: 100%;
                margin-left: 0px; }
              &:nth-child(2) {
                width: 100%;
                margin-right: 0px; } } }


          &:nth-child(2) {
            margin-top: 45px;
            .txt {
              height: auto; }
            .cus-col-6 {
              &:first-child {
                width: 100%;
                margin-right: 0px;
                margin-left: 0px; }

              &:nth-child(2) {
                width: 100%;
                margin-left: 0px;
                margin-right: 0px; } }
            .common-title {
              text-align: center; } } } } } } }

// About Legend
.cus-about-page.legend {
  .txt {
    .title {
      font-size: 18px;
      color: #fff;
      letter-spacing: 2px;
      margin-bottom: 10px; } }
  .about-section-1 {
    background: #1f2425;
    .about-intro {
      background-image: url('/ezfiles/764/1764/img/217/lengend_banner2.png'); } }

  .about-section-2 {
    margin-top: -1px;
    .custom-row .txt {
      display: block; }
    .common-title {
      text-align: center;
      margin-bottom: 30px; }
    .custom-row {
      .txt {
        padding: 0px 0px;
        background: transparent; } } }
  #section1, #section2 {
    &::before {
      content: '';
      display: block;
      margin-top: -90px;
      height: 90px;
      visibility: hidden; } } }

@media screen and (max-width: 767px) {
  .cus-about-page.legend {
    .txt {
      .title {
        font-size: 16px; } }
    .about-section-2 {
      .custom-row:nth-child(2) {
        margin-top: 0; }
      .txt .title {
        margin: 10px 0; } } } }

// About LEngend End



// About Awards
.cus-about-awards {
  padding-top: 36px;
  padding-bottom: 60px;
  background-image: url('/ezfiles/764/1764/img/217/award_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  .awards-wrap {
    padding: 103px 113px;
    background: #fff;
    border-radius: 40px; }
  .common-title {
    margin-top: 0;
    margin-bottom: 80px; }
  .cus-col-caculate-5 {
    margin-bottom: 40px; }
  .pic {
    img {
      width: 100%;
      max-width: 120px;
      display: block;
      margin: auto; } }
  .txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    p {
      color: #fff;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: normal;
      & + p {
        margin-top: 5px; } } } }

@media screen and (max-width: 767px) {
  .cus-about-awards {
    .awards-wrap {
      padding: 28px 38px; } } }

@media screen and (max-width: 576px) {
  .cus-about-awards {
    .txt {
      p {
        font-size: 13px; } } } }





// About Awards End

