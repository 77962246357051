$color_main: #d6b161;
// $color_hover: #91d400
// $color_border: #7db800
// $color_text: #243e36
// $color_title: #1a1a1a
// $color_header: #4d4d4d

@mixin size($w,$h) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t) {
  transition-duration: $t; }

