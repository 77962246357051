.cus-building-page {
  background-image: url('/ezfiles/764/1764/img/217/buliding_bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -2px;
  .building-top-area {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .selector {
      margin-left: auto; } }
  .mpgbar {
    width: 82%;
    max-width: 1400px;
    margin: auto;
    margin-bottom: 45px;
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px; }

  .selector {
    position: relative;

    select {
      width: auto;
      padding: 5px 25px 5px 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 0px;
      height: 30px;
      font-size: 14px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(/ezfiles/764/1764/img/217/select_arrow.svg) 86% 52% / 10% no-repeat #eee; }
    select::-ms-expand {
      display: none; } } }
.cus-building-page {
  .row.listBS {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0px; } } }
.cus-building {

  .building.gallery-top {
    margin-bottom: 10px;
    img {
      width: 100%;
      height: auto; } }
  .building-thumbs {
    .pic {
      overflow: hidden;
      img {
        width: auto;
        height: 73px; } } }

  .custom-container [class^='cus-col-'] {
    padding-left: 15px;
    padding-right: 15px; }

  .building-info {
    .txt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .building-title {
      margin-right: auto;
      margin-bottom: 5px;
      h5 a {
        color: #fff;
        font-size: 18px;
        letter-spacing: 1px; } }

    p {
      line-height: 2;
      font-size: 14px;
      color: #fff;
      letter-spacing: 1px;
      & + p {
        margin-top: 20px; } } }

  .swiper-button-next.swiper-button-white,
  .swiper-button-prev.swiper-button-white {
    position: absolute;
    width: 20px;
    height: 100%;
    background-size: 10px 20px;
    margin-top: 0px;
    transform: translateY(-50%);
    z-index: 100; }

  .swiper-button-next.swiper-button-white {
    right: 0;
    background-image: url('/ezfiles/764/1764/img/217/swiper_arrow_right.svg'); }

  .swiper-button-prev.swiper-button-white {
    left: 0;
    background-image: url('/ezfiles/764/1764/img/217/swiper_arrow_left.svg'); }


  .swiper-container.building-thumbs {
    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      background: #fff;
      z-index: 10; }
    &::before {
      left: 0px; }
    &::after {
      left: auto;
      right: 0px; } } }

@media screen and (max-width: 991px) {
  .cus-building-page {
    .building-top-area {
      .selector {
        margin: 0 auto;
        margin-bottom: 20px; } }
    .mpgbar {
      width: 70%; } }

  .cus-building {
    .building-info {
      .building-title {
        margin-top: 20px; } } }

  .cus-building {
    .cus-col-6 {
      width: 100%; } } }

@media screen and (max-width: 576px) {
  .cus-building-page {
    .mpgbar {
      width: 100%; } } }
