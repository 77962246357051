@import "./import/default.sass";
* {
  font-family: 'Microsoft JhengHei', Arial !important;
 }  // font-family: Arial,'Microsoft JhengHei'!important
@font-face {
  font-family: 'Franklin';
  src: url("http://ws048.so-buy.com/ezfiles/764/1764/img/246/ITC_Franklin_Gothic_Heavy_Italic.otf"); }
body {
  opacity: 0;
  background: #000; }

@media screen and (min-width: 768px) {
  .main {
    padding-top: 92px; } }

.mb {
  padding-top: 0; }

.custom-container {
  width: 82%;
  max-width: 1400px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative; }

.custom-row {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &.closest {
    padding-left: 15px;
    padding-right: 15px; }

  &.col {
    flex-direction: column; } }

.custom-container {
  [class^='cus-col-'] {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    // padding-left: 30px
 }    // padding-right: 30px

  .custom-row.closest [class^='cus-col-'] {
    padding-left: 0;
    padding-right: 0; } }
@media screen and (max-width: 992px) {
  .custom-container {
    width: 70%; } }
@media screen and (max-width: 576px) {
  .custom-container {
    width: 100%; } }

.cus-col-2 {
  width: 16.666666%; }

.cus-col-3 {
  width: 25%; }

.cus-col-4 {
  width: 33.333333%; }

.cus-col-5 {
  width: 41.666667%; }

.cus-col-6 {
  width: 50%; }

.cus-col-7 {
  width: 58.333333%; }

.cus-col-8 {
  width: 66.666667%; }

.cus-col-9 {
  width: 75%; }

.cus-col-10 {
  width: 83.333333%; }

.cus-col-12 {
  width: 100%; }

.cus-col-caculate-5 {
  width: 20%; }

@media screen and (max-width: 992px) {
  .cus-col-lg-5 {
    width: 41.666667%; }
  .cus-col-lg-6 {
    width: 50%; }

  .cus-col-lg-7 {
    width: 58.333333%; } }

@media screen and (max-width: 767px) {
  // .custom-container
  //   padding-left: 30px
  //   padding-right: 30p
  .cus-col-md-4 {
    width: 33.333333%; }

  .cus-col-md-6 {
    width: 50%; }

  .cus-col-md-12 {
    width: 100%; } }

@media screen and (max-width: 576px) {
  // .custom-container
  //   padding-left: 30px
  //   padding-right: 30px
  .cus-col-sm-6 {
    width: 50%; }

  .cus-col-sm-12 {
    width: 100%; } }



.custom-container.reverse .custom-row:nth-of-type(even) {
  flex-direction: row-reverse; }
.custom-container.odd-reverse .custom-row:nth-of-type(odd) {
  flex-direction: row-reverse; }

@media screen and (max-width: 767px) {
  .cus-order {
    order: -1; } }



// button
.cus-more {
  display: block;
  border: 1px solid #fff;
  background: rgba(0,0,0, .5);
  color: #fff;
  text-align: center;
  letter-spacing: 3px;
  padding: 10px 10px;
  font-size: 12px;
  margin-left: auto;
  width: 136px;
  // &:hover
 }  //   color: #fff

@media screen and (max-width: 992px) {
  .cus-more {
    padding: 6px 5px;
    width: 120px;
    font-size: 12px; } }

// button ENDDDDDD

// HEADER

@media screen and (max-width: 767px) {
  #header {
    position: relative;
    background: #fff; }

  h1.site-logo {
    margin: 5px 0;
    a {
      width: 60px; } }
  #main-nav {
    > li:hover {
      > ul {
        left: 50%;
        transform: translateX(-50%); } } } }

#header {
  padding: 0 15px; }
#main-nav {
  > li {
    padding: 30px 5px;

    img {
      width: 100%;
      height: auto; } }

  ul {
    li {
      text-align: center; } } }
@media screen and (max-width: 1400px) {
  #main-nav {
    > li {
      > a {
        letter-spacing: 1px;
        padding: 10px 5px 10px 26px;
        font-size: 16px; } } } }



// HEADER ENDDDD

// main-title
.common-title {
  margin: 40px 0;
  text-align: center;
  .cus-title {
    display: inline;
    position: relative;
    color: $color_main;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-bottom: 5px;
    font-style: italic; }
  span {
    position: relative;
    display: block;
    color: #fff;
    font-size: 1px;
    font-weight: bold;
    font-size: 26px;
    font-style: normal; } }

@media screen and (max-width: 767px) {
  .common-title {
     margin: 20px 0; } }

// main-title ENDDDDDDDD

// side-social
.social-link {
  list-style: none;
  position: fixed;
  padding-left: 0;
  z-index: 1000;
  right: 25px;
  top: 50%;
  li {
    margin-bottom: 22px; }
  a {
    img {
      max-width: 40px;
      width: 100%; } } }
@media screen and (max-width: 767px) {
  .social-link {
    right: 5px;
    a {
      img {
        max-width: 30px; } } } }

// side-social ENDDDDDD

// slider
.swiper-container.news-slider {
  .swiper-button-prev, .swiper-button-next {
    box-sizing: border-box;
    top: 59%;
    width: 30px;
    height: 30px;
    background-size: 30px auto;
    background-color: #fff;
    z-index: 1000;
    opacity: 1; }
  .swiper-button-prev {
    background-image: url('http://ws048.so-buy.com/ezfiles/764/1764/img/217/news_arrow_left.svg');
    left: 4px;
    background-size: 20px auto; }
  .swiper-button-next {
    background-image: url('http://ws048.so-buy.com/ezfiles/764/1764/img/217/news_arrow.svg');
    left: 170px;
    left: auto;
    right: 4px; } }

// @media screen and (max-width: 767px)
//   .swiper-button-prev, .swiper-button-next
//     display: none


// slider ENDDDDD


// cus-banner
.cus-banner {
  position: relative;
  // margin-top: 90px
  width: 100%;
  img {
    width: 100%; }
  h2 {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    font-style: italic;
    font-size: 36px; } }
@media screen and (max-width: 767px) {
  .cus-banner {
    margin-top: -2px;
    h2 {
      font-size: 18px; } } }


.fancybox-infobar {
  display: none; }





