@import "./import/default.sass";

.poorstock {
  color: $color_main; }

.cus-product-cate-page {
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px; }


#product-cate-item {
  padding-bottom: 50px;
  .mpro-price {
    display: none; }
  .listBS [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }
  .d-txt {
    text-align: center;
    h5 a {
      color: #fff; } }

  .mpgbar {
    padding-left: 15px;
    padding-right: 15px; }

  .form-control {
    border-radius: 0px;
    border-color: #b4b5b5;
    background-color: #696b6b; }
  .mpg-rel.pull-left select {
    background: url(/ezfiles/764/1764/img/217/select_arrow_white.svg) 86% 52% / 10% no-repeat #696b6b;
    color: #fff;
    font-size: 12px; }
  .mbtn {
    position: relative;
    padding: 8px 40px 8px 1em;
    background: #696b6b;
    color: #fff;
    border: 1px solid #b4b5b5;
    border-radius: 0px;
    letter-spacing: 2px;
    font-size: 12px;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 0 3px 6px;
      border-color: transparent transparent transparent #fff;
      top: 13px;
      right: 20px; }
    &.pg-prev {
      padding: 8px 1em 8px 40px;
      &::after {
        border-width: 3px 6px 3px 0px;
        border-color: transparent #fff transparent transparent;
        left: 20px; } } } }


@media screen and (max-width: 767px) {
  #product-cate-item {
    width: 100%; } }



// Product-Detail
body.no-cart {
  .maddtocart {
    display: none; }
  .mpgtitle.mpro-name {
    margin-bottom: 30px; } }

.cus-product-detail-page {
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  .product-cate-temp.detail {
    > .custom-container {
      padding-top: 100px; } }
  .ad-pro-main {
    color: #fff;
    dd.mval {
       > a {
          border: 1px solid #ddd;
          background-color: transparent;
          color: #fff;
          font-size: 12px;
          &.selected {
            background-color: $color_main;
            color: #fff;
            border: 2px solid $color_main;
            padding: 0;
            &::after {
              display: none; } }
          &:hover {
            border: 2px solid $color_main; } } }

    .input-group-btn .btn, .input-group-btn .mbtn {
      border-radius: 0;
      background: #ddd;
      color: #737373;
      &:hover {
        background: $color_main;
        color: #fff; } } }
  .ad-pro-btns {
    .mbtn-s {
      background-color: transpatent;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 0px;
      &:hover {
        background: $color_main; } } }
  // .thumb-large .owl-carousel .owl-item img
  .mpro-price {
    .mprice.oriprice {
      display: none; } }

  .mpgtitle {
    background: transparent;
    border-left: none;
    border-bottom: 1px solid #fff;
    h3 {
      padding: 0;
      color: #fff;
      font-size: 18px; } }
  .products-intro-content {
    p {
      margin-top: 10px;
      color: #fff;
      font-size: 16px; } }
  .maddtocart .cart-num-control {
    width: 60px;
    display: flex; }
  // .cart-num-control .form-control
  //   width: 20px
  // .maddtocart .cart-num-control
  //   width: 60px
  .input-group-addon, .input-group-btn, .cart-num-control .form-control {
    width: 33.333333%;
    margin: 0;
    padding: 0;
    .btn {
      padding: 0px;
      display: block;
      width: 100%;
      margin: 0; } }
  .owl-carousel .owl-dots .owl-dot span {
    background: #bebec0; }
  .owl-carousel .owl-dots .owl-dot.active span {
    background: $color_main; } }

.owl-carousel .owl-controls .owl-nav div {
  display: none;
  // .thumb-large .owl-carousel .owl-item img
  //   max-height: unset
  .figBS img {
    margin: unset; }


  #product-detail-item {
    .minner > .mb {
      padding-top: 0; } }

  .nav.nav-tabs {
    position: relative;
    border-bottom: none;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    > li {
      float: none; }
    > li > a {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 18px;
      padding: 0px; }
    &::after, &::before {
      content: '';
      display: block;
      position: absolute;
      left: 15px; }
    &::after {
      bottom: -10px;
      width: calc(100% - 30px);
      height: 1px;
      background: #747677;
      z-index: 10; }
    &::before {
      content: '';
      position: absolute;
      left: 15px;
      width: 30px;
      height: 2px;
      background: #fff;
      bottom: -11px;
      z-index: 100; } }
  #ptdet_tab_detail img {
    width: 100%; }

  .tab-content > .tab-pane {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0; } }
@media screen and (max-width: 767px) {

  .cus-product-detail-page {
    .cus-col-3.detail {
      width: 0;
      padding: 0; }
    .cus-col-9.detail {
      width: 100%; }
    .products-intro-content {
      p {
        font-size: 13px; } }


    .product-details-area {
      .product-detail-intro {
        padding-top: 140px; }
      .product-detail-selector .link-button {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        a {
          margin-left: 0px; } }
      .product-detail-selector ul.select {
        margin-left: 0px;
        width: 100%; }
      .product-detail-selector {
        flex-wrap: wrap;
        left: 0; } }
    .ad-pro-btns .mbtn-s {
      margin-top: 10px; }
    .mname {
      display: block;
      margin-bottom: 3px; }
    .mpro-price {
      margin-top: 10px; }
    .mpro-preview.col-sm-5 {
      margin-bottom: 30px; } } }

@media (min-width: 481px) {
  .cus-product-detail-page {
    .maddtocart {
      .mname {
        margin-bottom: 3px;
        width: auto;
        margin-right: 10px;
        display: block;
        vertical-align: middle;
        font-size: 14px;
        letter-spacing: 1px; }
      .maddnum, .mprice.last-price.curprice {
        .mname {
          display: inline-block; } } } } }





.product-details-area {
  position: relative;
  a, p {
    color: #fff; }
  .product-detail-intro {
    display: none;
    padding-top: 60px; }
  .link-button {
    > a {
      display: block;
      &.active {
        display: block; } } }
  .product-detail-selector {
    position: absolute;
    display: flex;
    right: 0;
    ul.select, .link-button a {
      color: white;
      border: 1px solid #fff;
      padding: 10px 20px;
      margin-left: 10px; }
    ul.select {
      background: #fff;
      color: #000;
      padding: 0;
      z-index: 100;
      li {
        padding: 10px 15px;
        z-index: 100;
        cursor: pointer;
        &:hover {
          background: #000;
          color: $color_main; } } } }


  .product-detail-content {
    margin-top: 20px; } }





// Product-Detail End







.lignt-box {
  position: fixed;
  z-index: 100000000;
  top: 0%;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: .7s;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; }


  .box {
    position: initial;
    transform: translate(0%, 0%);
    width: 80vw;
    margin: auto;
    padding: 40px;
    z-index: 10000;
    .close {
      position: absolute;
      font-size: 24px;
      width: 20px;
      height: 20px;
      text-align: center;
      top: 15px;
      right: 10px;
      cursor: pointer;
      color: #fff;
      z-index: 1000; } }


  img {
    display: block;
    width: 100%;
    opacity: 0;
    transform: translateX(-80px);
    transition: 1s;
    -webkit-overflow-scrolling: touch;
    z-index: 1000; }

  .box-mask {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(#000, .5);
    z-index: -1; }

  &.active {
    left: 0%;
    img {
      opacity: 1;
      transform: translateX(0); }
    .box-mask {
      left: 0%; } } }

// @media screen and (max-width: 991px)
//   .lignt-box
//     .box
//       padding: 30px 30px
//       img
//         width: calc(100% - 60px)
//         max-width: 450px

@media screen and (max-width: 576px) {
  .lignt-box {
    .box {
      // position: absolute
      // top: 50%
      // left: 50%
 } } }      // transform: translate(50%, 50%)

