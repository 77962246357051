.cus-building-page {
  .mpgbar {
    .mpg-rel {
      display: none; }
    .mpg-btn {
      display: none; } }

  .pagenavi {
    display: block;
    margin: 0 auto;
    padding: 0;
    position: relative;
    &:after {
      content: "";
      display: table;
      clear: both; }
    li {
      padding: 4px 10px;
      border-radius: 2px;
      margin: 0 3px;
      font-size: 13px;
      list-style-type: none;
      float: left;
      cursor: pointer;
      &.active {
        color: #fff; } }
    li:not(.active):hover {
      background-color: #f2f2f2; }
    a {
      display: block;
      width: 26px;
      height: 26px;
      line-height: 26px;
      overflow: hidden;
      border: transparent!important;
      padding: 0;
      margin: 0;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      &.none {
        display: none;
        cursor: initial; }
      &.prev {
       float: left; }
      &.next {
        position: relative; } } } }
