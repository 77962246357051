// table

div[class^="materials-"] h2.title.green-bg {
  margin: 0; }
.material-form {
  overflow-y: hidden; }
table.material-table {
  width: 100%;
  text-align: center;
  word-break: initial;


  img {
    width: 80px !important;
    margin-bottom: 0 !important; }

  th {
    padding: 12px 10px;
    text-align: center;
    letter-spacing: 2px;

    &.border-square {
      padding: 0; } }
  td {
    padding: 17px 10px; }

  tbody {
    border-bottom: 1px solid #fff;
    tr:nth-child(odd) {
      background: #1e1e1e;
      color: #fff; }

    tr:nth-child(even) {
      background: #545454;
      color: #fff; }


    th, td, a {
      color: #fff;
      font-size: 16px;
      letter-spacing: 2px; }
    td {
      font-weight: normal; } }



  thead {
    th {
      font-size: 16px;
      color: #fff;
      font-weight: normal; }

    tr {
      background: #545454; } } }

.scroll-sign {
  display: none; }

@media screen and (max-width: 767px) {
  table.material-table {
    tbody tr {
      th, td, a {
        font-size: 12px; } }

    thead th {
      font-size: 14px; } }

  .scroll-sign {
    display: block;
    .arrow-sign {
      font-size: 30px;
      text-align: center;
      animation-name: scrollSign;
      animation-duration: .6s;
      animation-delay: .5s;
      animation-iteration-count: infinite;
      animation-direction: alternate; } }

  @keyframes scrollSign {
    0% {
      transform: translateX(-10px); }

    100% {
      transform: translateX(10px); } } }

/* table End */

@media screen and (max-width: 767px) {
  .material-form {
    overflow-x: scroll;
    td, th {
      word-break: keep-all; } } }

