@import "./import/default.sass";
$color-1: $color_main;
$color-2: #fff;
$color-font: #222;

@mixin member-ui($w) {
  padding: 50px 45px;
  min-height: 550px;
  .mb,.mt {
    max-width: #{$w}px;
    margin: 0 auto 20px; } }


// 標題
.mt {
  margin-bottom: 20px;
  h3,h4 {
    border-color: $color-1;
    color: $color-1; } }

// 一般字樣
.ecconsignee-list {
  margin-top: 20px; }

// 彈跳視窗
.showother.open {
  z-index: 100000000; }


// 註冊條約
.mpopdiv .mt h4 {
  color: $color-font; }

// close ok
.ok span:before, .ok span:after, .close span:before, .close span:after {
  background-color: $color-font; }

// 登入頁面
.module-login {
  @include member-ui(400); }

// 註冊頁面
.module-register {
  @include member-ui(600);
  .formBS {
    .form-group {
      margin: 0 -15px; }
    .authcode+.form-group {
      margin-top: 15px;
      margin-left: 5px; } } }

// 我的收藏
.module-favlist {
  @include member-ui(800); }

// 修改密碼
.module-changepwd {
  @include member-ui(500);
  .form-group {
    margin: 0 -15px 10px;
    label {
      display: block;
      float: none;
      width: 100%; }
    .col-sm-9 {
      width: 100%; } } }

// 已填表單
.module-formlist {
  @include member-ui(800); }

// 會員
.module-memmenu {
  @include member-ui(600);
  .list-group-item a:hover {
    background-color: $color-1; } }

// 訂購紀錄
.module-orderlist {
  @include member-ui(1000); }

//退貨
.module-serviceform {
  @include member-ui(1000); }

// 購物車、訂單填寫
.module-viewcart,.module-billto {
  @include member-ui(1100);
  .mcart-operate .form-btn .mbtn {
    background-color: $color-1;
    border: none; } }

// 收貨地址
.module-consignee {
  @include member-ui(1000);
  .consignee-btn-add {
    margin: 0;
    border: solid 1px $color-1;
    color: $color-1;
    border-radius: 0;
    transition-duration: .5s;
    &:hover {
      background-color: $color-1;
      color: $color-2; } } }

// 我的折價卷、需求單紀錄
.module-coupon,.module-requirelist {
  @include member-ui(700); }

// 訂購人按鈕
.showother .consignee-btn-add {
  margin: 0;
  background-color: $color-1; }

// 新增訂購人
.ecconsignee-add .form-btn .col-xs-6 {
  padding: 0; }
.ecorder-form .showother > .mbox > div[class$="-add"] .form-btn .mbtn-top {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: $color-1;
  border-right: 1px solid $color-2;
  margin-right: -1px; }

// 按鈕
// 登入、註冊
.btn-login,.btn-register,.btn-s {
  border: solid 1px $color-1;
  color: $color-1;
  border-radius: 0;
  width: 100%;
  margin: 0;
  transition-duration: .5s;
  &:hover {
    background-color: $color-1;
    color: $color-2; } }


.page_mobileform .form-btn .btn.btn-s {
  width: auto; }

body.page_mobileacctinfo, body.page_mobilelogin, body.page_mobileforgetpass, body.page_registerfinish {
  .main {
    p, a, label, .desc {
      color: $color-1; }
    a {
      text-decoration: underline; } }

  .btn:hover, .btn:focus, .mbtn:hover, .mbtn:focus {
    background-color: $color-1; } }

body.page_mobileforgetpass {
  .desc {
    margin-bottom: 15px; }
  .btn {
    width: auto; }
  .row.form-group {
    margin-bottom: 20px; }
  .main {
    width: 82%;
    max-width: 1400px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px; } }

#_pop_register {
  p {
    color: #000; } }


body.page_mobile_meccart, body.page_mobile_mecbillto {
  a, span {
    color: #fff; }
  .uitable-th {
    background: $color-1;
    color: #fff;
    padding: 0 15px; }
  .mcartUl {
    padding: 0 15px; }
  .mpro-name a {
    color: #fff; }
  .mcart-operate {
    background: transparent;
    padding-top: 20px;
    border-top: 1px solid $color-1; }
  .module-cart .uith {
    text-align: center; }
  .module-cart .d-cart .mremove .ico {
    margin: auto;
    float: none; } }

body.page_mobile_mecbillto {
  .mname {
    display: block; }
  .ecpay-list {
    span {
      color: #7e7e7e; } }
  .showone.ecconsignee-def {
    p {
      color: #fff; } } }

body.page_mobile_meccheckout {
  .mcash {
    color: #fff;
    a {
      color: $color-1; } } }


.module.module-fav {
  display: none; }



@media screen and (min-width: 481px) {
  .module-cart .d-cart .mprice, .module-cart .d-cart .mqty, .module-cart .d-cart .mremove {
    text-align: center; } }

@media screen and (max-width: 767px) {
  body.page_mobile_meccart, body.page_mobile_mecbillto {
    .mcart-operate {
      padding-bottom: 20px !important;
      z-index: 1000000;
      background: #000;
      text-align: center; }
    .mname {
      display: block; }
    .mt h3 {
      font-size: 20px; } } }

@media screen and (min-width: 769px) {
  .ecorder-form .module-ecconsignee .consignee-btn-add[style="display: none;"] + .ecconsignee-add {
    height: auto;
    z-index: 1000000; } }

.mdialog .mdialog-foot .mbtn {
  color: $color-1;
  border: 2px solid $color-1;
  &:hover, &:focus {
    color: #fff;
    border: 2px solid $color-1;
    background: $color-1; } }


// 結帳頁面 sidebar
body.page_mobile_mecorderlist {
  div[class$="history"] ul[class*="-list"] div[class*="-details"] {
    z-index: 100000; } }

.deliver-desc .mval {
  color: #000; }



// 修改密碼
.module-changepwd .form-group label, .module-consignee .consignee-form .mfield .control-label, .row.listBS.listUi, .coupon-list, .requirehistory {
  color: $color-1; }








