// Knowledge
.cus-knowledge {
  position: relative;
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  padding-top: 37px;
  padding-bottom: 40px;
  p {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 2; }
  .txt-wrap {
    margin-bottom: 20px;
    padding-top: 52px;
    // padding-left: 10px
    // padding-right: 10px
    position: relative;
    background: #fff;
    border-radius: 25px;
    overflow: hidden;
    .gray-title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 15px 40px;
      cursor: pointer;
      background: #cdcccc;
      border-radius: 25px;
      z-index: 100;


      span {
        font-size: 16px;
        font-weight: 700;
        color: #000; }

      &.white {
        background: #fff; } }

    .case {
      display: none;
      background: #fff;
      padding: 15px 40px;
      position: relative;

      li {
        margin-bottom: 30px; }

      p {
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 15px; }
      a {
        word-break: break-word;
        &:hover {
          color: #000; } }

      .pic {
        img {
          display: block;
          margin-bottom: 45px;
          margin-top: 10px; } } } } }

@media screen and (max-width: 992px) {

  .cus-knowledge {
    .custom-container {
      width: 100%; } } }
