.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000000;
  .loading-mask {
    position: absolute;
    background: #000;
    height: 50vh;
    width: 100%;
    left: 0;
    &.top-area {
      top: 0; }
    &.bottom-area {
      bottom: 0; } }
  .line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0%;
    height: 1px;
    background: #fff;
    z-index: 100; } }







