.cus-area-page {
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -2px;
  .custom-row.area-info {
    img {
      width: 100%; }
    & + .custom-row.area-info {
      position: relative;
      margin-top: 94px;
      &::after, &::before {
        content: '';
        position: absolute;
        display: block;
        top: -47px;
        left: 15px; }

      &::after {
        width: calc(100% - 30px);
        background: #5e6061;
        height: 1px;
        z-index: 10; }
      &::before {
        top: -48px;
        width: 28px;
        background: #fff;
        height: 2px;
        z-index: 20; } }

    .cus-col-6:nth-child(2) {
      display: flex;
      flex-direction: column; } }



  .area-page.top-area {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    .selector {
      margin-left: auto;
      position: relative;
      select {
        width: auto;
        padding: 5px 25px 5px 10px;
        border-radius: 0px;
        font-size: 16px;
        border: 1px solid #ccc;
        height: 30px;
        font-size: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(/ezfiles/764/1764/img/217/select_arrow.svg) 86% 52% / 10% no-repeat #eee; }
      select::-ms-expand {
        display: none; } } }


  ul.company-info, .link-button {
    p, a {
      color: #fff;
      font-size: 18px;
      letter-spacing: 1px;
      opacity: 0;
      transition: opacity .3s linear; }
    &.active {
      p, a {
        opacity: 1; } } }

  ul.company-info {
    li {
      display: flex; }
    .company-name {
      p {
        font-size: 24px; } } }
  .link-button {
    margin-top: auto;
    a {
      display: inline-block;
      padding: 10px 20px;
      background: #919292;
      border: 1px solid #acadad;
      border-radius: 4px;
      font-size: 14px; } }

  .main-area {
    padding-bottom: 100px;
    > .area {
      display: none; } } }



@media screen and (max-width: 767px) {
  .cus-area-page {
    .custom-row.area-info {
      .cus-col-6 {
        width: 100%; } }

    .area-page.top-area {
      .selector {
        margin: auto;
        margin-bottom: 20px; } }

    ul.company-info, .link-button {
      p, a {
        font-size: 14px; } }
    ul.company-info {
      .company-name {
        p {
          margin-top: 15px;
          font-size: 20px; } } } } }
