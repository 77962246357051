.cus-diamond-page {
  padding-top: 50px;
  background: #1f2425;
  margin-top: -1px;

  img {
    width: 100%; }

  iframe {
    width: 100%; }

  p, .title {
    color: #fff; } }


.diamond-frame {
  margin-bottom: 95px;
  .common-title {
    margin-top: 0px;
    margin-bottom: 20px; } }

.diamond-difference {
  margin-bottom: 95px;
  .common-title {
    text-align: left; } }

.diamond-features {
  margin-bottom: 95px;

  .common-title {
    text-align: left; }

  .feature {
    .cus-col-3 {
      padding-left: 0; }
    .custom-row {
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; } }

  .txt {
    .title {
      margin-bottom: 15px;
      font-size: 16px; }
    p {
      font-size: 14px; } } }


.diamond-found {
  position: relative;
  margin-top: 95px;
  padding-bottom: 150px;
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: -68px;
    width: 12px;
    height: 25px;
    margin-top: 0px;
    z-index: 10;
    background-size: 12px 25px; }

  .swiper-button-next {
    right: 47%; }
  .swiper-button-prev {
    left: 47%; }
  .link-button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); }
  .link-button a {
    color: white;
    border: 1px solid #fff;
    padding: 10px 20px;
    margin-left: 0px; } }





// ani
.diamond-found {
  .swiper-slide {
    position: relative; }
  .swiper-slide:hover {
    .area-info {
      opacity: 1; }
    .area-info::after {
      transform: scale(1); } }
  .area-info {
    opacity: 0;
    position: absolute;
    z-index: 99;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    transition: all 0.35s;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: #333333;
      transform: scale(1.2);
      transition: all 0.35s;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
    .info {
      background: none;
      position: relative;
      height: 100%;
      z-index: 11;
      text-align: center;
      transition: all 0.35s;
      width: 100%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        position: relative;
        letter-spacing: 2px;
        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          bottom: -3px;
          height: 1px;
          width: 100%;
          background: #fff; } } } } }




@media screen and (max-width: 767px) {
  .cus-diamond-page {
    .common-title {
      .cus-title {
        font-size: 18px;
        padding-bottom: 15px;
        span {
          font-size: 14px;
          display: block;
          margin-top: 5px; } } }
    iframe {
      max-height: 32vh; }

    .diamond-frame {
      margin-bottom: 30px; }

    .diamond-difference {
      .pic {
        margin-top: 20px; } }

    .diamond-standard, .diamond-found {
      .custom-container {
        padding-left: 30px;
        padding-right: 30px; } }

    .diamond-found {
      padding-bottom: 105px;
      .swiper-button-next, .swiper-button-prev {
        bottom: -38px; }
      .swiper-button-next {
        right: 40%; }
      .swiper-button-prev {
        left: 40%; }
      .link-button {
        bottom: 20px; }
      .link-button a {
        font-size: 14px;
        padding: 10px 10px; } } } }



@media screen and (max-width: 576px) {
  .cus-diamond-page {
    .diamond-difference, .diamond-features {
      .common-title {
        text-align: center; } }

    .diamond-features {
      img {
        max-width: 150px;
        margin: auto;
        display: block; }
      .feature {
        text-align: center; } } } }
