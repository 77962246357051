.news-temp {
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  .row .col:last-child .mcol {
    margin-right: 0; }
  .row .col:first-child .mcol {
    margin-left: 0; }
  .mpg-rel.pull-left {
    select {
      width: auto;
      padding: 5px 25px 5px 10px;
      font-size: 16px;
      height: 30px;
      font-size: 14px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(/ezfiles/764/1764/img/217/select_arrow.svg) 86% 52% / 10% no-repeat #eee;
      select::-ms-expand {
        display: none; } } } }

.cus-video-page {
  padding-top: 80px;
  padding-bottom: 55px;
  iframe {
    display: block; }

  .mpgbar {
    .mpg-btn {
      display: none; } }

  .cus-banner.video {
    margin-bottom: 35px; }

  .video-top-area {
    margin-bottom: 55px;
    .custom-container {
      width: 85%; }
    .common-title {
      margin-bottom: 0; }
    .form-control.form-select {
      border-radius: 0px; }
    .mpg-rel.pull-left {
      float: right !important; } }


  .big-video-area {
    margin-bottom: 80px;
    .custom-container {
      max-width: 920px; }
    .video-frame {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    .video-date {
      text-align: right;
      color: #fff; } }

  .mbox {
    margin-bottom: 32px;
    background: #000;
    .d-txt {
      display: flex;
      flex-direction: column;
      color: #fff;
      a, i {
        color: #fff;
        letter-spacing: 1px; }
      h5 {
        display: block;
        padding-left: 15px;
        margin-top: 5px;
        .mdate {
          display: block;
          padding-left: 10px;
          font-size: 12px;
          &::before {
            content: '-'; } }
        a {
          font-size: 14px; } }

      .mdetail {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        order: -1;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; } } } } }

@media screen and (max-width: 992px) {
  .cus-video-page {
    padding-top: 0;
    .mbox {
      .d-txt {
        h5 a {
          font-size: 12px; } } }
    .video-top-area {
      margin-bottom: 20px; } } }

@media screen and (max-width: 767px) {
  .cus-video-page {
    .mbox {
      .d-txt {
        h5 a {
          font-size: 14px; } } } } }

@media screen and (max-width: 576px) {
  .cus-video-page {
    .row.listBS {
      padding-left: 30px;
      padding-right: 30px; }
    .mbox {
      .d-txt {
        h5 a {
          font-size: 12px; } } } } }

// Video End

// News Page
.cus-news-page {
  padding-bottom: 55px;

  .d-item[class*="h-il-"] .d-img {
    width: 100%;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
    img {
      width: 100%;
      transition: 3s linear;
      &:hover {
        transform: scale(1.2); } } }

  .news-top-area .custom-container {
    padding-right: 20px;
    padding-left: 20px; }


  #pageptlist {
    position: relative;
    width: 82%;
    max-width: 1400px;
    margin: 40px auto 0px auto;
    padding-left: 15px;
    padding-right: 15px;
    &::after, &::before {
      content: '';
      position: absolute;
      display: block; }
    &::after {
      top: -20px;
      height: 1px;
      background: #666868;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 40px); }
    &::before {
      width: 40px;
      background: #fff;
      height: 3px;
      top: -21px;
      z-index: 100;
      left: 20px; }
    .mpgbar {
      padding-left: 5px;
      padding-right: 5px; }
    .form-control {
      border-radius: 0px;
      border-color: #b4b5b5;
      background-color: #696b6b; }
    .mpg-rel.pull-left select {
      background: url(/ezfiles/764/1764/img/217/select_arrow_white.svg) 86% 52% / 10% no-repeat #696b6b;
      color: #fff;
      font-size: 12px; }
    .mbtn {
      position: relative;
      padding: 8px 40px 8px 1em;
      background: #696b6b;
      color: #fff;
      border: 1px solid #b4b5b5;
      border-radius: 0px;
      letter-spacing: 2px;
      font-size: 12px;
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px 0 3px 6px;
        border-color: transparent transparent transparent #fff;
        top: 13px;
        right: 20px; }
      &.pg-prev {
        padding: 8px 1em 8px 40px;
        &::after {
          border-width: 3px 6px 3px 0px;
          border-color: transparent #fff transparent transparent;
          left: 20px; } } } }
  .mbox {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding-right: 5px;
    padding-left: 5px;
    .d-txt {
      display: flex;
      margin-top: 10px; }
    a, i, h5 {
      color: #fff;
      letter-spacing: 2px;
      font-size: 12px;
      margin-bottom: 0px;
      margin-top: 0px;
      line-height: 1; }
    .news-title {
      margin-top: 10px;
      a {
        font-size: 14px; } } }

  .news-cate {
    h5 {
      padding-right: 5px;
      &::before {
        content: '-'; } } } }



@media screen and (max-width: 767px) {
  .cus-news-page {

    #pageptlist {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      &::after {
        top: -20px;
        height: 1px;
        background: #666868;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 70px); }
      &::before {
        width: 40px;
        background: #fff;
        height: 3px;
        top: -21px;
        z-index: 100;
        left: 35px; }
      .mpgbar {
        padding-left: 0;
        padding-right: 0; } }
    .row.listBS {
      display: flex;
      flex-wrap: wrap;

      .d-item.col-sm-3 {
        width: calc(50% - 1px);
        padding-left: 5px;
        padding-right: 5px; } }

    .mbox {
      padding-left: 0;
      padding-right: 0;
      .d-txt {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; }
      .news-title {
        text-align: center; }
      .news-cate {
        margin-bottom: 5px; } } } }

@media screen and (max-width: 576px) {
  .cus-news-page {
    .mbox {
      .news-title {
        a {
          font-size: 14px; } } } } }



@media screen and (max-width: 400px) {
  .cus-news-page {
    .mpg-btn.pull-right, .mpg-rel.pull-left, .mpg-rel.pull-left {
      float: none !important; }
    .mpg-rel.pull-left {
      display: inline-block;
      margin-top: 30px; } } }



// News Page End

// News Inner Page

.cus-news-inner-page {
  .mpgtitle {
    background: none;
    border-left: unset;
    h3 {
      padding: 0;
      color: #fff; }
    .mlarge.mfl .figBS {
      margin: 0; } }

  .module-inner {
    width: 82%;
    max-width: 1400px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 10;
    position: relative;
    .news-post-info {
      display: flex;
      margin-top: 10px;
      .news-cate h5 {
        margin-top: 0;
        margin-bottom: 0;
        &::before {
          content: '-'; } }
      .news-cate h5, .news-date {
        font-size: 12px;
        color: #fff;
        line-height: 1;
        letter-spacing: 2px; } }

    .news-main-title {
      .mpgtitle {
        margin-top: 15px;
        margin-bottom: 0;
        h3 {
          font-size: 21px; } } }

    .news-main-info {
      margin-top: 30px;
      p {
        color: #fff;
        font-size: 16px; } }
    img {
      width: 100%; }
    figure.figBS {
      img {
        max-width: 466px; } }
    .mcont {
      display: flex;
      flex-direction: column; } } }



@media screen and (min-width: 481px) {
  .cus-news-inner-page {
    .mlarge.mfl .figBS {
      margin: 0; } } }

@media screen and (max-width: 767px) {
  .cus-news-inner-page {
    .module-inner {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      .news-main-title {
        .mpgtitle {
          h3 {
            font-size: 18px; } } }
      .news-main-info {
        p {
          font-size: 14px;
          line-height: 1.6; } } } } }

// News Inner Page End
