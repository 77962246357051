@import "./import/default.sass";
.cus-policy-page {
  background-image: url('/ezfiles/764/1764/img/217/policy_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  padding-bottom: 111px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202425;
    z-index: -1; } }

.cus-policy-page, .cus-news-page {
  position: relative;
  .common-title {
    margin-top: 0;
    padding-top: 20px;
    text-align: center;
    & + .custom-row {
      justify-content: flex-end;
      ul {
        display: flex;
        li {
          padding-left: 15px; }
        a {
          display: block;
          padding: 10px 18px;
          color: #fff;
          background: #444646;
          transition: .3s linear;
          &:hover {
            background: $color_main; } } } } } }

.cus-policy-page .policy-area {
  position: relative;
  margin-top: 40px;
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(80% - 1px);
    height: 1px;
    background: $color_main; }
  &:after {
    width: 5%;
    height: 2px;
    background: #fff;
    left: calc(10% + 1px);
    transform: translateX(-0%); } }

.cus-news-page #pageptlist {
  &:before {
    left: calc(50% + 5px);
    width: calc(100% - 50px); }
  &:after {
    left: calc(0px + 20px);
    transform: translateX(0%); } }


.cus-policy-page {
  .policy-area {
    .txt {
      padding: 28px 38px;
      background: #fff; }
    .title {
      margin-bottom: 10px;
      font-size: 18px;
      letter-spacing: 2px; }
    p {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 20px;
      line-height: 2;
      letter-spacing: 1px; }
    .copy {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 1px; }

    .policy-info {
      display: none; } } }

@media screen and (max-width: 767px) {
  .cus-policy-page {
    .custom-container {
      padding-left: 30px;
      padding-right: 30px; } }
  .cus-policy-page, .cus-news-page {
    .common-title {
      & + .custom-row {
        justify-content: center;
        ul {
          li {
            padding-left: 5px;
            padding-right: 5px; }

          a {
            font-size: 14px; } } } } } }

// Member
.cus-policy-page.member {
  position: relative;
  background-image: url('/ezfiles/764/1764/img/217/policy_member_banner2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1px;
  .prod-detail {
    margin-bottom: 12px;
    padding-left: 15px; }

  .policy-area {
    &::before, &::after {
      display: none; }
    .policy-info {
      display: block; }
    .txt {
      padding: 103px 113px;
      background: #1e1e1e;
      border-radius: 40px;
      .title {
        position: relative;
        font-weight: bold;
        padding-left: 15px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -10px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #fff; } }

      p, .title {
        color: #fff; }
      p.note {
        margin-top: 3px;
        font-size: 14px; } }
    .section-wrap {
      margin-bottom: 35px;
      p {
        margin: 0;
        line-height: 2;
        font-weight: normal;
        padding-left: 15px; } } } }

@media screen and (max-width: 767px) {
  .cus-policy-page.member {
    .policy-area {
      .txt {
        padding: 28px 38px;
        p {
          font-size: 13px;
          margin-bottom: 15px; }
        .title {
          padding-left: 10px;
          font-size: 16px;
          &::before {
            width: 5px;
            height: 5px;
            left: -5px; } } } }
    .prod-detail {
      margin-bottom: 40px; } } }
// Member End
