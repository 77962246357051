// @media screen and (min-width: 768px)
//   body.page_mobilehome
//     .main
//       padding-top: 89px
@import "./import/default.sass";
.index-video-swiper {
  position: relative;
  .swiper-pagination.video-banner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }

  .swiper-pagination-bullet {
    margin: 0 3px;
    background:#ccc {}
    border: 1px solid #fff;
    outline: none; }
  .swiper-pagination-bullet-active {
    background: $color_main; } }




#video.swiper-container {
  .swiper-slide {
    .play {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; } }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  img {
    width: 100%;
    height: auto; } }


// News
.index-news {
  background: #212425;
  .custom-container {
    width: 90%;
    padding-left: 0;
    padding-right: 0;
    .cus-col-2 {
      width: calc(20% - 30px);
      margin-top: 45px; }
    .cus-col-10 {
      width: 80%; }
    .listBS [class*="col-"] {
      padding-left: 20px;
      padding-right: 20px; } }
  .swiper-container {
    padding: 145px 0 45px 0; }
  .mbox {
    width: 100%;
    position: relative;

    .news-tag {
      margin: 0 auto;
      top: calc(50% - 16px);
      left: 0;
      right: 0;
      width: 140px;
      display: inline-block;
      position: absolute;
      overflow: hidden;
      text-align: center;
      line-height: 1.2;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      font-size: 11px;
      font-weight: 600;
      transform: skewX(-12deg);
      a.view {
        padding: 10px;
        display: block;
        position: relative;
        z-index: 2;
        color: #fff;
        font-size: 16px;
        transform: translate3d(-101%, 0, 0);
        transition: transform cubic-bezier(0.8, 0, 0.2, 1) 0.6s; }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: #000;
        transform: translate3d(101%, 0, 0);
        transition: transform cubic-bezier(0.8, 0, 0.2, 1) 0.6s 0.2s; } }
    &:hover {
      .news-tag {
        a.view {
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2s; }
        &::before {
          transform: translate3d(0, 0, 0);
          transition-delay: 0s; } } } }
  .d-txt {
    h5 {
      display: flex;
      flex-direction: column; }
    .mdate.before {
      color: #fff;
      font-style: italic;
      font-size: 12px;
      letter-spacing: 1px; }
    a {
      color: #fff;
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: bold; } }
  .d-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    img {
      transition: 5s linear; }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, .4);
      transition: .3s linear; }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 1000;
      transition: 1.8s; }
    &.active {
      &::before {
        top: 0;
        bottom: 0;
        right: -100%;
        z-index: 2;
        background: #000;
        transition: cubic-bezier(0.8, 0, 0.2, 1) 1.3s; } } }



  .d-item {
    z-index: 10;
    &:hover {
      .d-img {
        img {
          transform: scale(1.2); }
        &::after {
          background: rgba(#000, 0); }
        .news-tag {
          width: 80px;
          padding: 5px 30px;
          transform: translate(0, -50%) skewX(-10deg);
          span {
            opacity: 1; } } } } }
  p.more {
    display: none; }
  .news-title-area {
    position: relative;
    margin: -70px 15px 0 15px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80%;
      background: #000;
      z-index: -1; }
    .txt {
      display: flex;
      flex-direction: column;
      z-index: 10;
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      left: -40px; }
    .cus-more {
      position: absolute;
      top: 55%;
      right: 10px;
      margin-top: 25px;
      background: rgba(#595959, .6); }
    span {
      color: #fff;
      font-weight: bold;
      &.title {
        font-family: Franklin !important;
        font-size: 48px;
        font-style: italic;
        position: relative;
        letter-spacing: 4px;
        padding-bottom: 10px;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          display: block;
          background: #fff; } }
      & + span {
        line-height: 2;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 15px; } } } }

@media screen and (min-width: 768px) {
  .index-news {
    .swiper-container {
      padding: 145px 0 82px 0; }
    .mbox {
      .d-txt {
        padding-left: 12px; }
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        background: #505252;
        top: -1000px;
        bottom: -1000px;
        left: 0; } } } }



@media screen and (max-width: 767px) {
  .index-news {
    padding-top: 30px;
    .swiper-container {
      padding: 15px 0 45px 0; }
    .custom-container {
      width: 100%;
      .cus-col-2 {
        width: 100%;
        margin-top: 0px; }
      .cus-col-10 {
        width: 100%; } }
    .news-title-area {
      .txt {
        display: flex;
        flex-direction: column;
        z-index: 10;
        position: relative;
        top: auto;
        transform: translateY(0);
        left: auto; }
      .cus-more {
        position: relative;
        top: auto;
        right: auto;
        margin-left: 1px;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 20px; }
      &::after {
        display: none; } } } }

// Automotive & Architecture
.bg-block {
  margin-top: -1px;
  transition: .5s linear;
  overflow: hidden;
  .cus-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 90px 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: .5s linear; }

  h2.common-title {
    position: relative;
    text-align: left;
    &::after, &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -20px;
      width: 100%;
      height: 1px;
      background: rgba(#fff, .3); }
    &::before {
       width: 3%;
       background: #fff;
       transition: .6s  linear; }

    .cus-title {
      font-size: 30px;
      font-weight: bold;
      font-style: italic;
      color: #fff;
      letter-spacing: 4px; } }
  &:hover, &.active {
    .cus-wrap {
      background-size: 110% 110%; }
    h2.common-title {
      &::before {
        width: 100%; } } }
  .txt {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 2;
    color: #fff;
    font-weight: bold; } }

@media screen and (max-width: 767px) {
  .bg-block {
    .cus-col-12 {
      padding-left: 30px;
      padding-right: 30px; }
    h2.common-title {
      .cus-title {
        font-size: 22px; } }
    .cus-more {
      margin-top: 20px; } } }


// Automotive
.index-automotive {
  position: relative;
  .cus-wrap {
    background-image: url('/ezfiles/764/1764/img/217/automotive_bg.png'); }
  .cus-more {
    background: rgba(#626165, .5); } }


// Architecture
.index-architecture {
  position: relative;
  .cus-wrap {
    background-image: url('/ezfiles/764/1764/img/217/tech_bg.png');
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(0,0,0,.6); } }
  .custom-container {
    z-index: 100; }
  .cus-more {
    background: rgba(#fff, .3); } }



// index Location
.index-location {
  padding-top: 30px;
  background: #090909;
  .common-title span {
    font-style: normal;
    letter-spacing: 1px; }
  .custom-container {
    width: 100%;
    .cus-col-3 {
      padding-left: 0;
      padding-right: 0; } }
  .custom-row {
    flex-wrap: nowrap; }
  .cus-location {
    position: relative;

    &::before, &::after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 0;
      height: 0;
      transition: 1s;
      z-index: 10; }
    &::before {
      border-bottom: 1px solid #fff; }
    &::after {
      border-right: 1px solid #fff; }

    a.location-wrap {
      position: relative;
      width: 100%;
      display: block;
      &::before, &::after {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        width: 0;
        height: 0;
        border-top: 1px solid #fff;
        transition: 1s; }
      &::before {
        border-top: 1px solid #fff; }
      &::after {
        border-left: 1px solid #fff; } }

    &.active {
      &::before {
        width: calc(100% - 20px); }
      &::after {
        height: calc(100% - 20px); }
      a.location-wrap {
        &::before {
          width: calc(100% - 20px); }
        &::after {
          height: calc(100% - 20px); } } } }

  .cus-location {
    position: relative;

    a.cus-more {
      position: absolute;
      background: rgba(#fff, .3);
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%); }
    .txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        color: #fff;
        padding: 5px 0;
        font-size: 13px;
        &:first-child {
          position: relative;
          font-weight: bold;
          letter-spacing: 2px;
          font-size: 17px;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            display: block;
            background: #fff; } } } } } }

@media screen and (max-width: 767px) {
  .index-location {
    .custom-row {
      flex-wrap: wrap; }
    .custom-container {
      padding-left: 0;
      padding-right: 0;
      .cus-col-3 {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 30px; } }
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50%;
      height: 350px; } } }

@media screen and (min-width: 768px) {
  .index-location {

    [class*="cus-col-3"] {
      position: relative;
      float: left;
      width: calc(100% / 4);
      padding: 0;
      overflow: hidden;
      filter: grayscale(70%);
      transition: all .4s;
      transition-duration: .5s;
      box-shadow: 5px 0px 30px #000;
      // &::after
      //   content: ''
      //   position: absolute
      //   top: 0
      //   left: 0
      //   width: 100%
      //   height: 100%
      //   background-color: rgba(0,0,0, .2)
      //   z-index: 1
      //   transition: all .4s
      &.hover {
        width: 40%;
        filter: grayscale(20%);
        &::after {
          background-color: rgba(0,0,0, 0); } }
      &.small {
        width: 20%; } } } }



