@import "./import/default.sass";
.footer {
  position: relative;
  background: #090909; }
.cus-footer {
  padding: 30px 0;
  .footer-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo-area {
      margin-bottom: 30px;
      width: 150px; }
    img {
      width: 100%; } }
  .company-info {
    text-align: center;
    > div {
      padding: 3px 0; }
    span {
      font-size: 12px; }
    a {
      font-size: 12px; } }
  nav.social {
    margin-top: 24px;
    img {
      // width: 22px
      width: 36px; }
    .footer-social {
      display: flex;
      li {
        padding: 0 5px;
        a {
          display: block; } } } }
  .top-group {
    position: absolute;
    top: 30px;
    right: 50px;
    cursor: pointer;
    width: 35px;
    z-index: 10000;
    text-align: center;
    p {
      text-transform: capitalize; }
    img {
      width: 35px; } } }

@media screen and (max-width: 767px) {
  .cus-footer {
    .top-group {
      width: 30px;
      right: 25px;
      img {
        width: 30px; } } } }

.cus-copyright {
  font-size: 12px;
  text-align: center;
  padding-bottom: 16px; }





.side-area {
  position: relative;
  width: 45px;
  height: 129px;
  background: $color_main;
  position: fixed;
  display: block;
  top: 45%;
  right: 0;
  z-index: 9999;
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    background: #fff; }
  .side-group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }
  img {
    width: 20px; } }
@media screen and (max-width: 767px) {
  .side-area {
    width: 30px; } }


