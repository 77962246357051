@media(min-width: 1024px) {
  .row_5.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_5.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_4.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_4.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_3.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_3.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_2.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_2.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_0.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_0.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_0.col1 .col_02 > .mcol > div + div, .footer > .minner, .header > .minner, .row_5.col3 > .mrow, .row_5.col2 > .mrow, .row_4.col3 > .mrow, .row_4.col2 > .mrow, .row_3.col3 > .mrow, .row_3.col2 > .mrow, .row_2.col3 > .mrow, .row_2.col2 > .mrow, .row_1 > .mrow {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto; } }
@media (min-width: 768px) {
  .row_5.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_5.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_4.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_4.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_3.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_3.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_2.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_2.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_0.col1 #Dyn_2_1 > div > div.module:not(.module-ads), .row_0.col1 #Dyn_2_1 > div.module:not(.module-ads), .row_0.col1 .col_02 > .mcol > div + div, .footer > .minner, .header > .minner, .row_5.col3 > .mrow, .row_5.col2 > .mrow, .row_4.col3 > .mrow, .row_4.col2 > .mrow, .row_3.col3 > .mrow, .row_3.col2 > .mrow, .row_2.col3 > .mrow, .row_2.col2 > .mrow, .row_1 > .mrow {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto; }
  .header > .minner {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto; } }
@media (min-width: 481px) {
  .row .col .mcol {
    padding: 0 0; } }
@media (max-width: 768px) {
  .wrap > .minner {
    padding-left: 0px;
    padding-right: 0px; } }
body, html {
  font-weight: 700;
  a:hover {
    color: #fff; } }
.mname {
  display: none; }
.module {
  margin-bottom: 0 !important; }
.d-item[class*="h-"] .mbox {
  overflow: unset; }
a:hover {
  text-decoration: none; }
.listBS [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }
.mpgdetail {
  padding-left: 0px;
  padding-right: 0px; }

.module.module-minicart {
  display: none; }

.row.listBS {
  margin-left: 0;
  margin-right: 0; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.go-pcurl {
  display: none; }

.hdmenu {
  display: none; }

.footer {
  padding: 0; }








// Owl
#product-detail-item.detail {
  .thumb-large .mlarge .owl-stage-outer {
    max-height: 500px; }

  .owl-carousel {
    .owl-item {
      img {
       width: auto;
       margin: unset; } } } }

// @media screen and (max-width: 767px)
//   #product-detail-item.detail
//     .mpro-preview.col-sm-5, .mpro-panel.col-sm-7
//       width: 100%



// Owl End

